<template>
  <div class="app-container">
    <div class="filter-container-box">
      <!-- <el-button icon="el-icon-arrow-left" size="small" @click="handleBack()">返回</el-button> -->
      <el-button class="filter-item" icon="el-icon-download" type="primary" size="small" @click="DownExcel()">导出 excel
      </el-button>
    </div>
    <!-- <div class="block">
    <span class="demonstration">选择时间段</span>
    <el-date-picker
    align="center"
      v-model="value2"
      type="datetimerange"
      :picker-options="pickerOptions"
      range-separator="至"
      start-placeholder="开始日期"
      end-placeholder="结束日期"
     >
    </el-date-picker>
  </div> -->

    <div class="table-text">上链信息统计表</div>

    <div class="table-head">
      <div>统计日期：{{ date }}</div>
      <div>统计部门：{{ department }}</div>
    </div>

    <div>
      <el-table :data="tableData" v-loading="loading" border style="width: 90%; margin-top: 20px; text-align: center">
        <el-table-column align="center" prop="rzpt" label="入驻平台企业" width="180"></el-table-column>
        <el-table-column align="center" prop="rcsz" :label="'其中1：入川首站企业'"></el-table-column>
        <el-table-column align="center" prop="zcfw" label="其中2：贮存服务企业"></el-table-column>
        <el-table-column align="center" prop="yscl" label="冷链运输车次数"></el-table-column>
        <el-table-column align="center" prop="jkll_zpc" label="进口冷链总批次"></el-table-column>
        <el-table-column align="center" prop="jkll_xdz" label="进口冷链总批次(其中：具有消毒证明的批次)"></el-table-column>
      </el-table>
    </div>
    <!-- <div class="table-text">注：长期市场、临时市场系指经批准建设的市场，农村集贸市场系指农村场镇历史自然形成的市场；食品批发市场指主要经营预包装食品的市场。</div>
   -->
  </div>
</template>

<script>


import axios from "../../../util/api";

export default {
  data () {
    return {
      tableData: [],
      department: "",
      date: "",
      loading: false,
    };
  },
  computed: {},
  created () { },
  mounted () {
    this.init();
  },
  methods: {
    handleBack () {
      this.$router.back();
    },
    init () {
      this.loading = true;
      axios
        .get("/pc/freezingInfoData/data-statistics")
        .then((response) => {
          const { rzpt, rcsz, zcfw, yscl, jkll_zpc, jkll_xdz } = response.data;
          this.tableData = [{ rzpt, rcsz, zcfw, yscl, jkll_zpc, jkll_xdz }];
          this.department = response.data.department;
          this.date = response.data.date;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
        });
    },
    handleDownload () {

    },

    DownExcel () {
      this.loading = true;
      const secounds = 5
      let num = 0
      this.timer = setInterval(() => {
        if (num < secounds) {
          num++
          this.check("export-freezingInfoData-list");
        } else {
          clearInterval(this.timer)
          this.loading = false
        }
      }, 2000)
      let statename
      statename = this.date + '日的上链信息统计表';
      axios.fileget('/pc/freezingInfoData/export-freezingInfoData-list', { downName: statename }).then((v) => { })
    },
    check (d_name) {
      axios.get('/pc/freezingInfoData/check', { down_name: d_name }).then((v) => { })

    },

    // 下载功能
    downloads (res) {
      if (!res) {
        return;
      }
      const url = window.URL.createObjectURL(
        new Blob([res], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
        })
      );
      let fname = "上链信息统计.xlsx";
      const link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      link.setAttribute("download", fname);
      document.body.appendChild(link);
      link.click();
    },
  },
};
</script>
<style>
.table-text {
  text-align: center;
}

.table-head {
  display: flex;
  flex-direction: column;
}
</style>
